<template>
  <div class="card p-3">
    <h2 class="text-xl font-semibold">Top comment</h2>
    <table v-if="topComment.data && topComment.data.length" class="mt-3 w-full">
      <tbody>
      <top-comment-item :item="item" :index="5 * (topComment.paginatorInfo.currentPage - 1) + index + 1"
                      v-for="(item, index) in topComment.data"
                      :key="'topComment' + index"/>
      </tbody>
    </table>
    <div class="mt-4">
      <v-pagination
          class="justify-content-end"
          v-if="topComment.paginatorInfo.lastPage > 1"
          v-model="topComment.paginatorInfo.currentPage"
          :pages="topComment.paginatorInfo.lastPage"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="loadTopComment"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import TopCommentItem from "./TopCommentItem";

export default {
  name: "TopComment",
  components: {TopCommentItem},
  props: {
    showPaging: Boolean
  },
  data() {
    return {
      topComment: {
        data: [],
        paginatorInfo: {
          total: 0,
          lastPage: 0,
          currentPage: 1
        }
      }
    }
  },
  methods: {
    loadTopComment() {
      let query = `query($page: Int) {
        topComment(first: 5, page: $page) {
          paginatorInfo {
            total
            lastPage
            currentPage
          }
          data {
            username
            avatar
            user_id
            total
          }
        }
      }
      `;

      ApiService.graphql(query, {page: this.topComment.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.topComment) {
              this.topComment = data.data.topComment;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadTopComment();
  }
}
</script>
