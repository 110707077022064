<template>
  <div class="card p-3">
    <div>
      <h2 class="text-xl font-semibold">{{ getNameFromPeoplePrefix(this.type) }} được nghe nhiều</h2>
    </div>

    <table class="mt-3 w-full">
      <tbody>
        <statistic-item-people :index="(statistics.paginatorInfo.currentPage-1) * 5 + index + 1" :total="people.total_listens" :prefix="getRoutePrefixPeople(this.type)" :people="people" v-for="(people, index) in statistics.data"
                          :key="'statisticItemPeople' + this.type + index"/>
      </tbody>
    </table>

    <div class="mt-4">
      <v-pagination
          class="justify-content-end"
          v-if="statistics.paginatorInfo.lastPage > 1"
          v-model="statistics.paginatorInfo.currentPage"
          :pages="statistics.paginatorInfo.lastPage"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="loadStatistic"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {getRoutePrefixPeople, getNameFromPeoplePrefix} from "../../core/services/utils.service";
import StatisticItemPeople from "./StatisticItem";

export default {
  name: "StatisticListenPeople",
  components: {StatisticItemPeople},
  props: {
    type: String
  },
  data() {
    return {
      statistics: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: null,
          total: 0
        }
      }
    }
  },
  methods: {
    loadStatistic() {
      let where = {
        AND: [{column: "total_listens", operator: "GT", value: 0}]
      }

      if (this.type == 'artist') {
        where.AND.push({column: "is_group", value: 0});
      }

      let query = `query($first: Int, $page: Int, $where: WhereConditions) {
        ${this.type}s(where: $where, first: $first, page: $page, orderBy: [{column: "total_listens", order: DESC}]) {
          data {
            id
            title
            slug
            total_listens
            avatar {
              url
            }
          }
          paginatorInfo {
            currentPage
            total
            lastPage
          }
        }
      }
      `;

      ApiService.graphql(query, {where: where, first: 5, period: this.period, type: this.type, page: this.statistics.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data[this.type + "s"] && data.data[this.type + "s"].data) {
              this.statistics = data.data[this.type + "s"];
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    getRoutePrefixPeople(x) {
      return getRoutePrefixPeople(x.toLowerCase());
    },
    getNameFromPeoplePrefix(x) {
      return getNameFromPeoplePrefix(this.getRoutePrefixPeople(x));
    },
  },
  mounted() {
    this.loadStatistic();
  },
  watch: {
    type: {
      handler() {
        this.statistics.paginatorInfo.currentPage = 1;
        this.loadStatistic();
      }
    },
    period: {
      handler() {
        this.statistics.paginatorInfo.currentPage = 1;
        this.loadStatistic();
      }
    }
  }
}
</script>
