<template>
  <tr class="" v-if="people">
    <td class="px-2 py-2 text-emerald-600 font-medium">
      <span :class="'inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full ' + getBgClass(index)">{{ index }}</span>
    </td>
    <td class="space-x-4 px-2 py-2 text-emerald-600 font-medium flex items-center">
      <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <router-link v-if="people.slug" :title="people.title" class="text-lg font-semibold" :to="{name: 'PeopleDetail', params: {slug: people.slug, prefix: prefix}}">
          <img :alt="people.title" :src="people.avatar ? people.avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="w-full h-full rounded-full object-cover mr-2">
        </router-link>
      </div>
      <div class="flex-1">
        <router-link v-if="people.slug" :title="people.title" class="text-lg font-semibold" :to="{name: 'PeopleDetail', params: {slug: people.slug, prefix: prefix}}">
          {{ people.title }}
        </router-link>
      </div>
    </td>
    <td class="px-2 py-2 text-emerald-600 font-medium whitespace-nowrap" :uk-tooltip="'title: ' + thousandFormat(total) + ' lượt nghe'">
      <i class="uil uil-headphones"></i> {{ numberFormat(total) }}
    </td>
  </tr>
</template>

<script>
import { numberFormat, thousandFormat } from "../../core/services/utils.service";

export default {
  name: "StatisticItemPeople",
  props: {
    prefix: String,
    total: Number,
    index: Number,
    people: {
      type: Object,
      default() {
        return {
          title: "",
          total_listens: 0
        };
      }
    }
  },
  components: {

  },
  methods: {
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    getBgClass(index) {
      switch (index) {
        case 1:
          return 'bg-pink-800';
        case 2:
          return 'bg-pink-600';
        case 3:
          return 'bg-pink-400';
        default:
          return 'bg-pink-400';
      }
    }
  }
}
</script>
