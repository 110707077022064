<template>
  <div>
      <div>
        <top-listen/>
      </div>
      <div class="mt-4">
        <top-point/>
      </div>
      <div class="mt-4">
        <top-like/>
      </div>
      <div class="mt-4">
        <top-upload/>
      </div>
      <div class="mt-4">
        <top-comment/>
      </div>
      <div class="mt-4">
        <statistic-listen-people type="composer"/>
      </div>
      <div class="mt-4">
        <statistic-listen-people type="artist"/>
      </div>
      <div class="mt-4">
        <statistic-listen-people type="recomposer"/>
      </div>
      <div class="mt-4">
        <statistic-listen-people type="poet"/>
      </div>
      <div class="mt-4">
        <statistic-listen :show-views="true" :show-karaoke="true" :show-paging="true"/>
      </div>
  </div>
</template>

<script>
import TopListen from "../../statistic/TopListen";
import TopPoint from "../../statistic/TopPoint";
import TopLike from "../../statistic/TopLike";
import TopUpload from "../../statistic/TopUpload";
import TopComment from "../../statistic/TopComment";
import StatisticListen from "../../statistic/StatisticListen.vue";
import StatisticListenPeople from "../../people/StatisticListen.vue";
import { changePageTitle } from "../../../core/services/utils.service";
import { getUser } from "../../../core/services/jwt.service";

export default {
  name: "Overall",
  components: {TopListen, TopPoint, TopLike, TopUpload, TopComment, StatisticListen, StatisticListenPeople},
  mounted() {
    let user = getUser();
    if (!user || user.username !== "BQT") {
      this.$router.push({name: "Home"});
      return;
    }
    changePageTitle("Thống kê");
  }
}
</script>
  